import SkeletonLoader from 'components/SkeletonLoader';
import SettingsLayout from 'pages/settings/components/SettingsLayout';
import { Suspense } from 'react';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import PrivateDashboard from 'routers/PrivateRoute';
import PublicRoute from 'routers/PublicRoute';
import { Loadable } from 'utils/ComponentLoader';
import './App.css';
import './assets/fontello/css/mdoc.css';
import PostHogProvider from './utils/postHog/PostHogProvider';

const RegistrationPage = Loadable(() => import('pages/authentication/RegistrationPage'));
const ChangePasswordPage = Loadable(() => import('pages/authentication/ChangePasswordPage'));
const LoginPage = Loadable(() => import('pages/authentication/LoginPage'));
const DashboardPage = Loadable(() => import('pages/dashboard/DashboardPage'));
const MembersPage = Loadable(() => import('pages/members/MembersPage'));
const ReferralsPage = Loadable(() => import('pages/referrals/ReferralsPage'));
const ConsultsPage = Loadable(() => import('pages/consults/ConsultsPage'));
// const SingleConsultPage = Loadable(() => import('pages/consults/SingleConsultPage'));
const OldPartnerConsultPage = Loadable(() => import('pages/consults/OldPartnerConsultPage'));
const SinglePartnerConsultPage = Loadable(() => import('pages/consults/SinglePartnerConsultPage'));
const ChatPage = Loadable(() => import('pages/chatPage/ChatPage'));
const OutliersPage = Loadable(() => import('pages/outliers/OutliersPage'));
const AppointmentsPage = Loadable(() => import('pages/appointments/AppointmentsPage'));
const ProfilePage = Loadable(() => import('pages/profile/ProfilePage'));
const ProfileChangePasswordPage = Loadable(() => import('pages/profile/ProfileChangePasswordPage'));
const PrivacySettingsPage = Loadable(() => import('pages/settings/PrivacySettingsPage'));
const SingleMemberPage = Loadable(() => import('pages/single-members-page/SingleMemberPage'));
const ForgotPasswordPage = Loadable(() => import('pages/authentication/ForgotPasswordPage'));
const SingleForumDetailsPage = Loadable(() => import('pages/forum/SingleForumDetailsPage'));
const Forumpage = Loadable(() => import('pages/forum/Forumpage'));
const HealthDiaryWeightPage = Loadable(() => import('pages/health-diary/HealthDiaryWeightpage'));
const HealthDiaryExercisePage = Loadable(
  () => import('pages/health-diary/HealthDiaryExcercisePage'),
);
const HealthDiaryHeightPage = Loadable(() => import('pages/health-diary/HealthDiaryHeightPage'));
const HealthDiaryBMIPage = Loadable(() => import('pages/health-diary/HealthDiaryBMIPage'));
const HealthDiaryPSALevelpage = Loadable(() => import('pages/health-diary/HealthDiaryPSALevel'));
const HealthDiaryBloodPressurePage = Loadable(
  () => import('pages/health-diary/HealthDiaryBloodPressurePage'),
);
const HealthDiaryTemperaturePage = Loadable(
  () => import('pages/health-diary/HealthDiaryTemperaturePage'),
);
const HealthDiaryPregnancyPage = Loadable(
  () => import('pages/health-diary/HealthDiaryPregnancyPage'),
);
const HealthDiaryMedicationPage = Loadable(
  () => import('pages/health-diary/HealthDiaryMedicationPage'),
);
const HealthDiaryCholesterolPage = Loadable(
  () => import('pages/health-diary/HealthDiaryCholesterolPage'),
);
const HealthDiaryWaistCircumferencePage = Loadable(
  () => import('pages/health-diary/HealthDiaryWaistCircumferencePage'),
);
const HealthDiaryBloodSugarPage = Loadable(
  () => import('pages/health-diary/HealthDiaryBloodSugarPage'),
);
const HealthConditionsHistoryPage = Loadable(
  () => import('pages/health-passport/HealthConditionsHistoryPage'),
);
const MedicationsHistoryPage = Loadable(
  () => import('pages/health-passport/MedicationsHistoryPage'),
);
const PrescriptionsHistoryPage = Loadable(
  () => import('pages/health-passport/PrescriptionsHistoryPage'),
);
const PrescriptionsHistoryDetailsPage = Loadable(
  () => import('pages/health-passport/PrescriptionsHistoryDetailsPage'),
);
const EyeExamHistoryPage = Loadable(() => import('pages/health-passport/EyeExamHistoryPage'));
const FootExamHistoryPage = Loadable(() => import('pages/health-passport/FootExamHistoryPage'));
const ProceduresHistoryPage = Loadable(() => import('pages/health-passport/ProceduresHistoryPage'));
const MyProviderHistoryPage = Loadable(() => import('pages/health-passport/MyProviderHistoryPage'));
const MyLabHistoryPage = Loadable(() => import('pages/health-passport/MyLabHistoryPage'));
const MyPharmacyHistoryPage = Loadable(() => import('pages/health-passport/MyPharmacyHistoryPage'));
const SingleBlaHistory = Loadable(() => import('pages/single-members-page/SingleBlaHistory'));
const BLAFilter = Loadable(() => import('pages/bla-filter/BLAFilter'));
const HubVisit = Loadable(() => import('pages/hub-visit'));
const SingleHubVisitPage = Loadable(() => import('pages/hub-visit/SingleHubVisitPage'));
const CoachCheckIn = Loadable(() => import('pages/settings/CoachCheckIn'));

function App() {
  return (
    <>
      <BrowserRouter>
        <PostHogProvider>
          <Routes>
            <Route
              path="/register"
              element={
                <Suspense fallback={<SkeletonLoader />}>
                  <PublicRoute>
                    <RegistrationPage />
                  </PublicRoute>
                </Suspense>
              }
            />
            <Route
              path="/"
              element={
                <Suspense fallback={<SkeletonLoader />}>
                  <PublicRoute>
                    <LoginPage />
                  </PublicRoute>
                </Suspense>
              }
            />
            <Route
              path="/member-consult/:id"
              element={
                <Suspense fallback={<SkeletonLoader />}>
                  <OldPartnerConsultPage />
                </Suspense>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <Suspense fallback={<SkeletonLoader />}>
                  <PublicRoute>
                    <ForgotPasswordPage />
                  </PublicRoute>
                </Suspense>
              }
            />
            <Route
              path="/resetPassword/:token"
              element={
                <Suspense fallback={<SkeletonLoader />}>
                  <PublicRoute>
                    <ChangePasswordPage />
                  </PublicRoute>
                </Suspense>
              }
            />

            <Route path="/*" element={<PrivateDashboard />}>
              {/* all dashboard routes here */}
              <Route
                path="dashboard"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <DashboardPage />
                  </Suspense>
                }
              />
              <Route
                path="members"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <MembersPage />
                  </Suspense>
                }
              />
              <Route
                path="referrals"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <ReferralsPage />
                  </Suspense>
                }
              />
              <Route
                path="consults"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <ConsultsPage />
                  </Suspense>
                }
              />
              <Route
                path="consults/:id"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <SinglePartnerConsultPage />
                  </Suspense>
                }
              />
              <Route
                path="Outliers"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <OutliersPage />
                  </Suspense>
                }
              />
              <Route
                path="appointments"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <AppointmentsPage />
                  </Suspense>
                }
              />
              <Route
                path="hub-visit"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <HubVisit />
                  </Suspense>
                }
              />
              <Route
                path="hub-visit/:id"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <SingleHubVisitPage />
                  </Suspense>
                }
              />
              <Route
                path="bla-filter"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <BLAFilter />
                  </Suspense>
                }
              />
              <Route
                path="appointments"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <AppointmentsPage />
                  </Suspense>
                }
              />
              <Route
                path="settings"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <Navigate to="/settings/profile" />
                  </Suspense>
                }
              />
              <Route
                path="settings/profile"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <SettingsLayout>
                      <ProfilePage />
                    </SettingsLayout>
                  </Suspense>
                }
              />
              <Route
                path="settings/privacy"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <SettingsLayout>
                      <PrivacySettingsPage />
                    </SettingsLayout>
                  </Suspense>
                }
              />
              <Route
                path="settings/check-in"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <SettingsLayout>
                      <CoachCheckIn />
                    </SettingsLayout>
                  </Suspense>
                }
              />
              <Route
                path="settings/change-password"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <SettingsLayout>
                      <ProfileChangePasswordPage />
                    </SettingsLayout>
                  </Suspense>
                }
              />
              <Route
                path="members/:id"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <SingleMemberPage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/:programId"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <SingleMemberPage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/:programId/health-passport/health-condition-history"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <HealthConditionsHistoryPage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/:programId/health-passport/medications-history"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <MedicationsHistoryPage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/:programId/health-passport/prescriptions-history"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <PrescriptionsHistoryPage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/:programId/health-passport/prescriptions-history/:prescriptionId"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <PrescriptionsHistoryDetailsPage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/:programId/health-passport/eye-exam-history"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <EyeExamHistoryPage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/:programId/health-passport/foot-exam-history"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <FootExamHistoryPage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/:programId/health-passport/provider-history"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <MyProviderHistoryPage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/:programId/health-passport/pharmacy-history"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <MyPharmacyHistoryPage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/:programId/health-passport/lab-history"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <MyLabHistoryPage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/:programId/health-passport/procedures-history"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <ProceduresHistoryPage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/health-diary/weight"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <HealthDiaryWeightPage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/health-diary/blood-pressure"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <HealthDiaryBloodPressurePage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/health-diary/psa-level"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <HealthDiaryPSALevelpage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/health-diary/exercise"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <HealthDiaryExercisePage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/health-diary/medication"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <HealthDiaryMedicationPage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/health-diary/cholesterol"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <HealthDiaryCholesterolPage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/health-diary/pregnancy"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <HealthDiaryPregnancyPage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/health-diary/temperature"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <HealthDiaryTemperaturePage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/health-diary/waist-circumference"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <HealthDiaryWaistCircumferencePage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/health-diary/blood-sugar"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <HealthDiaryBloodSugarPage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/health-diary/bmi"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <HealthDiaryBMIPage />
                  </Suspense>
                }
              />
              <Route
                path="members/:id/health-diary/height"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <HealthDiaryHeightPage />
                  </Suspense>
                }
              />
              <Route
                path={'members/:id/:programId/bla/:date'}
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <SingleBlaHistory />
                  </Suspense>
                }
              />
              <Route
                path={'members/:id/bla/:date'}
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <SingleBlaHistory />
                  </Suspense>
                }
              />
              <Route
                path="chat"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <ChatPage />
                  </Suspense>
                }
              />
              <Route
                path="forum"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <Forumpage />
                  </Suspense>
                }
              />
              <Route
                path="forum/:id"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <SingleForumDetailsPage />
                  </Suspense>
                }
              />
            </Route>
          </Routes>

          <NotificationContainer />
        </PostHogProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
